let user = JSON.parse(process.env.REACT_APP_USER_TYPES);

export const UserType = {
  super: user?.super,
  admin: user?.admin,
  management: user?.management,
  user: user?.user,
  student: user?.student,
  changed: user?.changed,
  parent: user?.parent,
  applicant: user?.applicant,
};

let role = JSON.parse(process.env.REACT_APP_USER_ROLE);

export const UserRole = {
  super: role?.super,
  admin: role?.admin,
  management: role?.management,
  user: role?.user,
  student: role?.student,
  visitor: role?.visitor,
  parent: role?.parent,
  applicant: role?.applicant,
};

export const SubModulesName = {
  // setup: {
  AcademicSession: "Academic Session",
  Class: "Class And Section",
  Section: "Class And Section",
  Stream: "Stream",
  Documents: "Documents",
  Subject: "Subject",
  ClassSection: "Class And Section",
  Group: "Group",
  Configuration: "Config",
  Houses: "Houses",
  OtherSetting: "Other Setting",
  CollegeInformation: "College Information",
  Bank: "Bank And Branches",
  Branch: "Bank And Branches",
  ClassGroupSubject: "Class Section Subject Mapping",
  Route: "Transport",
  TransportCircle: "Transport",
  Stops: "Transport",
  HostelType: "Hostel And Room",
  HostelRoom: "Hostel And Room",
  RouteStop: "Transport",
  HostelBlock: "Hostel And Room",
  LeaveRemarks: "Leave Remark",
  TransportStop: "Transport",
  HolidayCalendar: "Holiday Calendar",
  ManageDesignation: "Manage Designation",
  ManageDepartment: "Manage Department",
  AdmissionNumberFormat: "Admission Number Format",
  TemplateLibrary: "Template Library",
  VendorManagement: "Vendor Management",
  // },
  // admission: {
  StudentInformation: "Student Information",
  StudentStrength: "Student Strength",
  StudentImageImport: "Student Image Import",
  StudentExcelImport: "Student Excel Import",
  CertificationSLC: "Certification Slc",
  RollNoAllotment: "Roll No Allotment",
  StudentAdmissionReport: "Student Report",
  StudentActivity: "Student Activity",
  // GenerateReport: "Generate Report And Editing",
  FieldWiseModification: "Student Field Wise Modification",
  // LeavingInfo: "Student Leaving info",
  StudentMulti: "Student Official Multi",
  StudentSubject: "Student Subject",
  ShuffleClasses: "Student Promotion",

  // },
  // fee: {
  FeeGroup: "Manage Fee Structure",
  FeeInstallment: "Manage Fee Structure",
  FeeComponent: "Manage Fee Structure",
  FeeStructure: "Manage Fee Structure",
  ManageFine: "Fine",
  ChequeBounce: "Cheque Bounce",
  StudentFeeStructure: "Student Fee Component Structure",
  ConcessionName: "Manage Fee Concession",
  ConcessionStructure: "Manage Fee Concession",
  StudentConcession: "Student Concession",
  DailyFeeCollection: "Student Fee Collection",
  MisFeeCollection: "Student Fee Collection",
  FeeRefund: "Fee Refund",
  GenerateFeeReport: "Student Fee Report",
  FeeSummary: "Student Fee Report",
  StudentFeeAccount: "Student fee account",
  FeeCertificate: "Fee Certificate",
  OnlinePayment: "Online fee payment",
  // },
  // user: {
  UserInfo: "User Info",
  UserLog: "User Log",
  User: "User",
  UserRight: "User Permission",
  // },
  // sms: {
  SmsTemplate: "Sms Template",
  StudentGeneralSMS: "Student General Sms",
  StudentTransportSMS: "Student Transport Sms",
  StudentSMS: "Student Sms",
  StudentFeeSMS: "Student Fee Sms",
  ResultSMS: "Result Sms",
  AttendanceSMS: "Attendance Sms",
  LibrarySMS: "Student Library Sms",
  EmployeeGeneralSMS: "Employee General Sms",
  EmployeeLeaveSMS: "Employee Leave Sms",
  EmployeeLibrarySMS: "Employee Library Sms",
  EmployeeSalarySMS: "Employee Salary Sms",
  EmployeeTaxSMS: "Employee Income Tax Sms",
  EmployeeIncomeSMS: "Employee Increment Sms",
  EmployeeArrearNBonus: "Employee Arrear & Bonus Sms",
  ImportExcel: "Excel Import Sms",
  EnquirySMS: "Enquiry Sms",
  RegistrationSMS: "Registration Sms",
  GroupImport: "Group Import",
  GroupSMS: "Group Sms",
  GeneralGroupSMS: "General Group Sms",
  LogSMS: "Sms Log",
  // },
  // attendance: {
  StudentAttendance: "Student Attendance And Leave",
  StudentSmartCard: "SmartCard Student Attendance",
  StudentReport: "Student Attendance Report",
  // },
  // webCms: {
  Category: "Category",
  AwardAndAchievement: "Award And Achievement",
  BulletinBoard: "Bulletin Board",
  Circular: "Circular",
  Events: "Event",
  Page: "Media Press",
  News: "News",
  PhotoGallery: "Gallery",
  Slider: "Slider",
  Publication: "Publication",
  Thought: "Thought Of The Day",
  VideoGallery: "Video Gallery",
  // },
  // academicCms: {
  HomeReport: "Homework Report",
  AnnualCalendar: "Annual Calender",
  AnnualPlan: "Annual Plan",
  Assignment: "Assignment",
  DateSheet: "Datesheet",
  Homework: "Homework",
  QuestionBank: "Question Bank",
  Syllabus: "Syllabus",
  Timetable: "Time Table",
  Tutorials: "Tutorials",
  // },
  // webManager: {
  MediaAssetPage: "Media Asset",
  MenuManager: "Menu Manager",
  PageSection: "Page Section",
  WebPage: "Web Page",
  // },

  // result: {
  BoardRollNumber: "Board Roll No",
  CBSEResultProcessing: "CBSE Result Processing",
  EvaluationSheet: "Evaluation Sheet",
  FreezeUnfreezeTerms: "Freeze Unfreeze Terms",
  MarksEntry: "Marks Entry",
  MarksheetFinalSetting: "Marksheet Final Setting",
  ProcessFinalResult: "Process Final Result",
  RemarkEntry: "Remark Entry",
  RemarkInfo: "Remark Info",
  RemarksField: "Remarks Field",
  ResultConfiguration: "Result Configuration",
  ResultReports: "Result Reports",
  ResultSettings: "Result Settings",
  SubjectDescriptiveIndicators: "Subject Descriptive Indicators",
  PromotionAndDemotion: "Promotion And Demotion",
  TermsAndSubjectMarksSetting: "Terms And Subject Marks Setting",
  ResultVisibity: "Result Visibility",
  // },

  // || Start: Notification
  StudentGeneralNotifications: "Student General Notifications",
  StudentFeeNotifications: "Student Fee Notifications",
  StudentTransportNotifications: "Student Transport Notifications",
  AttendanceNotifications: "Attendance Notifications",
  NotificationsLog: "Notifications Log",
  // || End: Notification

  // || Start: Payroll
  PayrollSettings: "Payroll Settings",
  PayrollEmployeeInfo: "Payroll Employee Info",
  PayrollFWM: "Field Wise Modification",
  SalaryGeneration: "Salary Generation",
  PayrollReports: "Payroll Reports",
  // || End: Payroll

  //whatsapp
  StudentWhatsapp: "Student Whatsapp Message",
  WhatsappLog: "Whatsapp Log",

  //start: Registration
  RegistrationCriteria: "Registration Criteria",
  RegistrationRules: "Registration Rules",
  RegistrationFee: "Registration Fee",
  RegistrationBatch: "Registration Batch",
  RegistrationInstruction: "Registration Instruction",
  RegistrationSelection: "Registration Selection",
  RegistrationForm: "Registration Form",
  RegistrationReports: "Registration Reports",

  //Library
  LibrarySetting: "Library Settings",
  LibraryNewEntry: "Library New Entry",
  LibraryActivities: "Library Activities",
  LibraryStocks: "Library Stock",
  LibrarySearchEngine: "Library Search Engine",
  LibraryPass: "Library Pass",
  LibraryReports: "Library Reports",

  //staff attendance start-------------------------------------------

  //--- geofence  start------
  staffGeofenceLocationSettings: "Geofence Location",
  geofenceLocationAllotment: "Geofence Location Allotment",
  //--- geofence  end------
  // staffSettings: "Financial Session",
  staffFinancialSettings: "Financial Session",
  staffEntryStepper: "Employee Details",

  staffAttendanceLog: "Attendance Log",
  //---  shifts start------
  staffShiftSetting: "Shift Setting",
  staffDepartmentShiftAssignment: "Department Shift Assignment",
  staffEmpShiftAllotment: "Employee Shift Allotment",
  //---  shifts end ------

  //--- leave settings start------
  staffLeaveType: "Leave Type",
  staffLeaveName: "Leave Name",
  staffLeaveStructure: "Leave Structure",
  //--- leave settings end------
  //red mark start ----------------------
  staffRedMarkConfiguration: "Redmark Configuration",
  staffRedMarkAllotment: "Redmark Allotment",
  staffRedMarkReports: "Redmark Report",
  //red mark end ----------------------

  staffAssignHoliday: "Assign Holiday",
  staffAttendance: "Attendance",
  staffLeaves: "Advance Leave",
  staffRegularizeRequest: "Attendance Regularization",
  staffWFHRequest: "Work From Home Request",
  staffCarryForwardLeave: "Carry Forward Leave",
  staffMachineIdAllotment: "Machine Id Allotment",
  // staffReports: "Reports",
  staffLeaveSummaryReports: "Leave Summary Report",
  staffMonthlyAttendanceSummaryReports: "Monthly Attendance Summary Report",
  staffConsolidatedReports: "Employee Attendance Consolidated Report",
  //staff attendance ends ---------------------------------------------

  // || start: Visitor Management
  VisitorManagementSettings: "Visitor Management Settings",
  VisitorManagementScheduledMeeting: "Scheduled Meeting",
  VisitorManagementBulkVisit: "Bulk Visit",
  VisitorManagementTimeTrack: "Time Tracking",
  VisitorManagementUnScheduledMeeting: "Unschedule Meeting",
  VisitorManagementVisitorsEnquiry: "Visitors Enquiry",

  // lesson Planning
  lessonCreation: "Lesson Creation",
  teacherMapping: "Teacher Mapping",
  timeEntry: "Time Entry",
  lessonReports: "Lesson Planning Reports",
  // lessonReports: "Reports",
  teacherDiary: "Teacher Diary",

  //recruitment
  RecruitmentJobPost: "Recruitment Job Post",
  ApplicantReports: "Applicant Reports",
  CreateJobPost: "Create Job Post",
};

export const AllPages = {
  super: {
    p1: "layouts.allPages.super.p1",
    p2: "layouts.allPages.super.p2",
    p3: "layouts.allPages.super.p3",
    p4: "layouts.allPages.super.p4",
    p5: "layouts.allPages.super.p5",
    p6: "layouts.allPages.super.p6",
    p7: "layouts.allPages.super.p7",
    p8: "layouts.allPages.super.p8",
    p9: "layouts.allPages.super.p9",
    p10: {
      s0: "layouts.allPages.super.p10.s0",
      s1: "layouts.allPages.super.p10.s1",
      s2: "layouts.allPages.super.p10.s2",
    },
    p11: "layouts.allPages.super.p11",
    p12: "layouts.allPages.super.p12",
    p13: "layouts.allPages.super.p13",
    p14: "layouts.allPages.super.p14",
    p15: "layouts.allPages.super.p15",
  },
  admin: {
    p1: "layouts.allPages.admin.p1",
    p2: "layouts.allPages.admin.p2",
    p3: {
      s0: "layouts.allPages.admin.p3.s0",
      s1: "layouts.allPages.admin.p3.s1",
      s2: "layouts.allPages.admin.p3.s2",
      s3: "layouts.allPages.admin.p3.s3",
      s3a: "layouts.allPages.admin.p3.s3a",
      s3b: "layouts.allPages.admin.p3.s3b",
      s3c: "layouts.allPages.admin.p3.s3c",
      s4: "layouts.allPages.admin.p3.s4",
      s5: "layouts.allPages.admin.p3.s5",
      s6: "layouts.allPages.admin.p3.s6",
      s7: "layouts.allPages.admin.p3.s7",
      s8: "layouts.allPages.admin.p3.s8",
      s9: "layouts.allPages.admin.p3.s9",
      s10: "layouts.allPages.admin.p3.s10",
      s11: "layouts.allPages.admin.p3.s11",
      s12: "layouts.allPages.admin.p3.s12",
      s13: "layouts.allPages.admin.p3.s13",
      s14: "layouts.allPages.admin.p3.s14",
      s15: "layouts.allPages.admin.p3.s15",
      s16: "layouts.allPages.admin.p3.s16",
      s17: "layouts.allPages.admin.p3.s17",
      s18: "layouts.allPages.admin.p3.s18",
      s20: "layouts.allPages.admin.p3.s20",
    },
    p4: {
      s0: "layouts.allPages.admin.p4.s0",
      s1: "layouts.allPages.admin.p4.s1",
      s2: "layouts.allPages.admin.p4.s2",
      s3: "layouts.allPages.admin.p4.s3",
      s4: "layouts.allPages.admin.p4.s4",
      s5: "layouts.allPages.admin.p4.s5",
      s6: "layouts.allPages.admin.p4.s6",
      s7: "layouts.allPages.admin.p4.s7",
      s8: "layouts.allPages.admin.p4.s8",
      s9: "layouts.allPages.admin.p4.s9",
      s10: "layouts.allPages.admin.p4.s10",
      s11: "layouts.allPages.admin.p4.s11",
      s12: "layouts.allPages.admin.p4.s12",
      s13: "layouts.allPages.admin.p4.s13",
      s14: "layouts.allPages.admin.p4.s14",
      s15: "layouts.allPages.admin.p4.s15",
      s16: "layouts.allPages.admin.p4.s16",
      s17: "layouts.allPages.admin.p4.s17",
      s18: "layouts.allPages.admin.p4.s18",
    },
    p5: {
      s0: "layouts.allPages.admin.p5.s0",
      s1: "layouts.allPages.admin.p5.s1",
      s2: "layouts.allPages.admin.p5.s2",
      s3: "layouts.allPages.admin.p5.s3",
      s4: "layouts.allPages.admin.p5.s4",
      s5: "layouts.allPages.admin.p5.s5",
      s6: "layouts.allPages.admin.p5.s6",
      s7: "layouts.allPages.admin.p5.s7",
      s8: "layouts.allPages.admin.p5.s8",
      s9: "layouts.allPages.admin.p5.s9",
      s10: "layouts.allPages.admin.p5.s10",
      s11: "layouts.allPages.admin.p5.s11",
      s12: "layouts.allPages.admin.p5.s12",
      s13: "layouts.allPages.admin.p5.s13",
      s14: "layouts.allPages.admin.p5.s14",
      s15: "layouts.allPages.admin.p5.s15",
      s16: "layouts.allPages.admin.p5.s16",
      s17: "layouts.allPages.admin.p5.s17",
      s18: "layouts.allPages.admin.p5.s18",
      s19: "layouts.allPages.admin.p5.s19",
      s20: "layouts.allPages.admin.p5.s20",
      s21: "layouts.allPages.admin.p5.s21",
    },
    p6: {
      s0: "layouts.allPages.admin.p6.s0",
      s1: "layouts.allPages.admin.p6.s1",
      s2: "layouts.allPages.admin.p6.s2",
      s3: "layouts.allPages.admin.p6.s3",
      s4: "layouts.allPages.admin.p6.s4",
      s5: "layouts.allPages.admin.p6.s5",
      s6: "layouts.allPages.admin.p6.s6",
      s7: "layouts.allPages.admin.p6.s7",
      s8: "layouts.allPages.admin.p6.s8",
      s9: "layouts.allPages.admin.p6.s9",
      s10: "layouts.allPages.admin.p6.s10",
    },
    p7: {
      s0: "layouts.allPages.admin.p7.s0",
      s1: "layouts.allPages.admin.p7.s1",
      s2: "layouts.allPages.admin.p7.s2",
      s3: "layouts.allPages.admin.p7.s3",
      s4: "layouts.allPages.admin.p7.s4",
    },
    p8: {
      s0: "layouts.allPages.admin.p8.s0",
      s1: "layouts.allPages.admin.p8.s1",
    },
    p9: {
      s0: "layouts.allPages.admin.p9.s0",
      s1: "layouts.allPages.admin.p9.s1",
      s2: "layouts.allPages.admin.p9.s2",
      s3: "layouts.allPages.admin.p9.s3",
      s4: "layouts.allPages.admin.p9.s4",
      s5: "layouts.allPages.admin.p9.s5",
      s6: "layouts.allPages.admin.p9.s6",
      s7: "layouts.allPages.admin.p9.s7",
      s8: "layouts.allPages.admin.p9.s8",
      s9: "layouts.allPages.admin.p9.s9",
      s10: "layouts.allPages.admin.p9.s10",
      s11: "layouts.allPages.admin.p9.s11",
      s12: "layouts.allPages.admin.p9.s12",
      s13: "layouts.allPages.admin.p9.s13",
      s14: "layouts.allPages.admin.p9.s14",
      s15: "layouts.allPages.admin.p9.s15",
      s16: "layouts.allPages.admin.p9.s16",
      s17: "layouts.allPages.admin.p9.s17",
      s18: "layouts.allPages.admin.p9.s18",
      s19: "layouts.allPages.admin.p9.s19",
    },
    p10: {
      s0: "layouts.allPages.admin.p10.s0",
      s1: "layouts.allPages.admin.p10.s1",
      s2: "layouts.allPages.admin.p10.s2",
      s3: "layouts.allPages.admin.p10.s3",
      s4: "layouts.allPages.admin.p10.s4",
    },
    p11: {
      s0: "layouts.allPages.admin.p11.s0",
      s1: "layouts.allPages.admin.p11.s1",
      s2: "layouts.allPages.admin.p11.s2",
      s3: "layouts.allPages.admin.p11.s3",
      s4: "layouts.allPages.admin.p11.s4",
      s5: "layouts.allPages.admin.p11.s5",
      s6: "layouts.allPages.admin.p11.s6",
      s7: "layouts.allPages.admin.p11.s7",
      s8: "layouts.allPages.admin.p11.s8",
      s9: "layouts.allPages.admin.p11.s9",
    },
    p12: {
      s0: "layouts.allPages.admin.p12.s0",
      s1: "layouts.allPages.admin.p12.s1",
      s2: "layouts.allPages.admin.p12.s2",
      s3: "layouts.allPages.admin.p12.s3",
      s4: "layouts.allPages.admin.p12.s4",
      s5: "layouts.allPages.admin.p12.s5",
      s6: "layouts.allPages.admin.p12.s6",
      s7: "layouts.allPages.admin.p12.s7",
    },
    p13: "layouts.allPages.admin.p13",
    p14: "layouts.allPages.admin.p14",
    p15: {
      s0: "layouts.allPages.admin.p15.s0",
      s1: "layouts.allPages.admin.p15.s1",
      s2: "layouts.allPages.admin.p15.s2",
      s3: "layouts.allPages.admin.p15.s3",
      s4: "layouts.allPages.admin.p15.s4",
      s5: "layouts.allPages.admin.p15.s5",
      s6: "layouts.allPages.admin.p15.s6",
      s7: "layouts.allPages.admin.p15.s7",
      s8: "layouts.allPages.admin.p15.s8",
    },
    p16: "layouts.allPages.admin.p16",
    p17: {
      s0: "layouts.allPages.admin.p17.s0",
      s1: "layouts.allPages.admin.p17.s1",
      s2: "layouts.allPages.admin.p17.s2",
      s3: "layouts.allPages.admin.p17.s3",
      s4: "layouts.allPages.admin.p17.s4",
      s5: "layouts.allPages.admin.p17.s5",
      s6: "layouts.allPages.admin.p17.s6",
    },
    p18: {
      s0: "layouts.allPages.admin.p18.s0",
      s1: "layouts.allPages.admin.p18.s1",
      s2: "layouts.allPages.admin.p18.s2",
      s3: "layouts.allPages.admin.p18.s3",
      s4: "layouts.allPages.admin.p18.s4",
      s5: "layouts.allPages.admin.p18.s5",
    },
    p19: {
      s0: "layouts.allPages.admin.p19.s0",
      s1: "layouts.allPages.admin.p19.s1",
      s2: "layouts.allPages.admin.p19.s2",
      s3: "layouts.allPages.admin.p19.s3",
      s4: "layouts.allPages.admin.p19.s4",
      s5: "layouts.allPages.admin.p19.s5",
    },
    p20: "layouts.allPages.admin.p20",
    p21: {
      s0: "layouts.allPages.admin.p21.s0",
      s1: "layouts.allPages.admin.p21.s1",
      s2: "layouts.allPages.admin.p21.s2",
      s3: "layouts.allPages.admin.p21.s3",
      s4: "layouts.allPages.admin.p21.s4",
      s5: "layouts.allPages.admin.p21.s5",
      s6: "layouts.allPages.admin.p21.s6",
      s7: "layouts.allPages.admin.p21.s7",
      s8: "layouts.allPages.admin.p21.s8",
      s9: "layouts.allPages.admin.p21.s9",
      s10: "layouts.allPages.admin.p21.s10",
      s11: "layouts.allPages.admin.p21.s11",
    },
  },
  manager: {
    p1: "layouts.allPages.manager.p1",
    p2: "layouts.allPages.manager.p2",
  },
  student: {
    p1: "layouts.allPages.student.p1",
    p2: {
      s0: "layouts.allPages.student.p2.s0",
      s1: "layouts.allPages.student.p2.s1",
      s2: "layouts.allPages.student.p2.s2",
      s3: "layouts.allPages.student.p2.s3",
    },
    p3: {
      s0: "layouts.allPages.student.p3.s0",
      s1: "layouts.allPages.student.p3.s1",
      s2: "layouts.allPages.student.p3.s2",
    },
    p4: "layouts.allPages.student.p4",
    p5: "layouts.allPages.student.p5",
    p6: "layouts.allPages.student.p6",
    p7: "layouts.allPages.student.p7",
    p8: "layouts.allPages.student.p8",
    p9: "layouts.allPages.student.p9",
    p10: "layouts.allPages.student.p10",
    p11: "layouts.allPages.student.p11",
    p12: "layouts.allPages.student.p12",
    p13: "layouts.allPages.student.p13",
    p14: "layouts.allPages.student.p14",
    p15: "layouts.allPages.student.p15",
    p16: "layouts.allPages.student.p16",
    p17: "layouts.allPages.student.p17",
    p18: "layouts.allPages.student.p18",
  },
  applicant: {
    p1: "layouts.allPages.applicant.p1",
    p2: "layouts.allPages.applicant.p2",
    p3: "layouts.allPages.applicant.p3",
  },
};

export const Translate = {
  student: {
    search: "StudentModule.search",
    b1: "StudentModule.b1", // close
    b2: "StudentModule.b2", // submit
    dashboard: {
      fee: "StudentModule.dashboard.fee",
      leave: "StudentModule.dashboard.leave",
      apply: "StudentModule.dashboard.apply",
      attendance: "StudentModule.dashboard.attendance",
      total: "StudentModule.dashboard.total",
      due: "StudentModule.dashboard.due",
      days: "StudentModule.dashboard.days",
      adv: "StudentModule.dashboard.adv",
    },
    fee: {
      general: {
        total: "StudentModule.fee.general.total",
        heading: "StudentModule.fee.general.heading",
        s1: "StudentModule.fee.general.s1",
        s2: "StudentModule.fee.general.s2",
        details: "details",
        adv: "StudentModule.fee.general.adv",

        tableHeading: {
          h1: "StudentModule.fee.general.tableHeading.h1",
          h2: "StudentModule.fee.general.tableHeading.h2",
          h3: "StudentModule.fee.general.tableHeading.h3",
          h4: "StudentModule.fee.general.tableHeading.h4",
          h5: "StudentModule.fee.general.tableHeading.h5",
          h6: "StudentModule.fee.general.tableHeading.h6",
        },
        tableSubHEading: {
          h1: "StudentModule.fee.general.tableSubHEading.h1",
          h2: "StudentModule.fee.general.tableSubHEading.h2",
          h3: "StudentModule.fee.general.tableSubHEading.h3",
          h4: "StudentModule.fee.general.tableSubHEading.h4",
          h5: "StudentModule.fee.general.tableSubHEading.h5",
          h6: "StudentModule.fee.general.tableSubHEading.h6",
        },
      },
      history: {
        detailHeading: "StudentModule.fee.history.detailHeading",
        export: "StudentModule.fee.history.export",
        tableHeading: {
          h1: "StudentModule.fee.history.tableHeading.h1",
          h2: "StudentModule.fee.history.tableHeading.h2",
          h3: "StudentModule.fee.history.tableHeading.h3",
          h4: "StudentModule.fee.history.tableHeading.h4",
          h5: "StudentModule.fee.history.tableHeading.h5",
          h6: "StudentModule.fee.history.tableHeading.h6",
        },
        details: {
          s1: "StudentModule.fee.history.details.s1",
          s2: "StudentModule.fee.history.details.s2",
          s3: "StudentModule.fee.history.details.s3",
          s4: "StudentModule.fee.history.details.s4",
          s5: "StudentModule.fee.history.details.s5",
          s6: "StudentModule.fee.history.details.s6",
          s7: "StudentModule.fee.history.details.s7",
        },
      },
    },
    attendance: {
      leave: {
        tableHeading: {
          h1: "StudentModule.attendance.leave.tableHeading.h1",
          h2: "StudentModule.attendance.leave.tableHeading.h2",
          h3: "StudentModule.attendance.leave.tableHeading.h3",
          h4: "StudentModule.attendance.leave.tableHeading.h4",
          h5: "StudentModule.attendance.leave.tableHeading.h5",
        },
        apply: {
          heading: "StudentModule.attendance.leave.apply.heading",
          f1: "StudentModule.attendance.leave.apply.f1",
          f2: "StudentModule.attendance.leave.apply.f2",
          f3: "StudentModule.attendance.leave.apply.f3",
        },
      },
    },
    otp: {
      heading: "StudentModule.otp.heading",
      regenerate: "StudentModule.otp.regenerate",
    },
    profile: {
      profileTabs: {
        official: "StudentModule.profile.profileTabs.official",
        personal: "StudentModule.profile.profileTabs.personal",
        parent: "StudentModule.profile.profileTabs.parent",
        documents: "StudentModule.profile.profileTabs.documents",
        academicSession: "StudentModule.profile.profileTabs.academicSession",
      },
    },
  },
};

export const AWSTagsForDocs = {
  TUTORIALS: {
    img: "academic-cms-tutorials-img",
    doc: "academic-cms-tutorials-doc",
  },
  HOMEWORK: {
    img: "academic-cms-homework-img",
    doc: "academic-cms-homework-doc",
  },
  DATESHEET: {
    img: "academic-cms-datesheet-img",
    doc: "academic-cms-datesheet-doc",
  },
  ASSIGNMENT: {
    img: "academic-cms-assignment-img",
    doc: "academic-cms-assignment-doc",
  },
  TIME_TABLE: {
    img: "academic-cms-timetable-img",
    doc: "academic-cms-timetable-doc",
  },
  SYLLABUS: {
    img: "academic-cms-syllabus-img",
    doc: "academic-cms-syllabus-doc",
  },
  QUESTION_BANK: {
    img: "academic-cms-questionbank-img",
    doc: "academic-cms-questionbank-doc",
  },
  ANNUAL_PLAN: {
    img: "academic-cms-anualplan-img",
    doc: "academic-cms-anualplan-doc",
  },
  ANNUAL_CALENDER: {
    img: "academic-cms-anualcalendar-img",
    doc: "academic-cms-anualcalendar-doc",
  },
  NEWS: {
    img: "web-cms-news-img",
    doc: "web-cms-news-doc",
  },
  EVENT: {
    img: "web-cms-event-img",
    doc: "web-cms-event-doc",
  },
  CIRCULAR: {
    img: "web-cms-circular-img",
    doc: "web-cms-circular-doc",
  },
  GALLERY: {
    img: "web-cms-gallery-img",
  },
  SLIDER: {
    img: "web-cms-slider-img",
  },
  PUBLICATION: {
    img: "web-cms-publication-img",
    doc: "web-cms-publication-doc",
  },
  MEDIA_PRESS: {
    img: "web-cms-media_press-img",
    doc: "web-cms-media_press-doc",
  },
  BULLETIN_BOARD: {
    img: "web-cms-bulletin_board-img",
    doc: "web-cms-bulletin_board-doc",
  },
  AWARD_AND_ACHIEVEMENT: {
    img: "web-cms-award_and_achievement-img",
    doc: "web-cms-award_and_achievement-doc",
  },
  STUDENT_INFO_IMG: {
    img: "student-info-img",
    doc: "student-info-doc",
  },
  STUDENT_INFO_IMG_FATHER: {
    img: "student-info-img-father",
    doc: "student-info-doc-father",
  },
  STUDENT_INFO_IMG_MOTHER: {
    img: "student-info-img-mother",
    doc: "student-info-doc-mother",
  },
  STUDENT_INFO_IMG_GUARDIAN: {
    img: "student-info-img-gaurdian",
    doc: "student-info-doc-gaurdian",
  },
  COLLEGE_MEDIA: {
    img: "college-media",
  },
  EMPLOYEE_MACHINE_ID: {
    img: "employee-attendance-machineId",
  },
  EMPLOYEE_DAILY_ATTENDANCE: {
    img: "employee-daily-attendance-import",
  },
  STUDENT_INFO_EXCEL: {
    img: "student-record-excel",
  },
  BOARD_ROLL_NUMBER_ALLOTMENT: {
    img: "board-roll-number-allotment",
  },
  MARK_ENTRY: {
    img: "mark-entry",
  },
  WEBSITE_TEMPLATE: {
    img: "web-manager-template-media",
    doc: "web-manager-template-media",
  },
  WEBSITE_WEB: {
    img: "web-manager-page-media",
    doc: "web-manager-page-media",
  },
  REPORT_TEMPLATE: {
    img: "report-template-section",
    doc: "report-template-section",
  },
  COLLEGE_EXCECL_IMPORT: {
    img: "college-excel",
  },
  GROUP_SMS_EXCEL: {
    img: "group-sms-excel",
  },
  TRANSPORT_ROUTE_IMPORT: {
    img: "student-transport-excel",
    doc: "student-transport-excel",
  },
  CONCESSION_IMPORT: {
    img: "student-concession-excel",
    doc: "student-concession-excel",
  },
  HOSTEL_IMPORT: {
    img: "student-hostel-excel",
    doc: "student-hostel-excel",
  },
  EMPLOYEE_IMAGE_PAYROLL: {
    img: "employee-info-img",
  },
  SUBJECT_IMPORT: {
    img: "subject-import",
    doc: "subject-import",
  },
  FEE_COLLECTION_IMPORT: {
    img: "fee-collection-excel",
    doc: "fee-collection-excel",
  },
  STUDENT_REGISTRATION_DOCUMENT: {
    img: "student-registration-documents",
    doc: "student-registration-documents",
  },
  STUDENT_REGISTRATION_INSTRUCTIONS: {
    img: "registration-instructions",
    doc: "registration-instructions",
  },
  EMPLOYEE_IMAGE: {
    img: "employee-info-img",
  },
  STAFF_ATTENDANCE_LEAVEOPENING: {
    img: "employee-leave-opening",
    // doc: "employee-leave-opening",
  },
  JOB_POST: {
    img: "recruitment-jobpost",
    doc: "recruitment-jobpost",
  },
  VISITOR_MANAGEMENT: {
    img: "visitor-attachment",
  },
  VISITOR_IMPORT: {
    img: "visitor-import",
    doc: "visitor-import",
  },
  FEE_GROUP_EXCEL: {
    img: "fee-group",
    doc: "fee-group",
  },
  FEE_COMPONENT_EXCEL: {
    img: "fee-component",
    doc: "fee-component",
  },
  LESSON_PLANNING_IMPORT: {
    doc: "lesson-import",
    img: "lesson-import",
  },
  LESSON_PLANNING: {
    doc: "lesson-attachment",
    img: "lesson-attachment",
  },
  JOB_APPLY: {
    img: "recruitment-job-apply",
    doc: "recruitment-job-apply",
  },
  FWM_IMPORT: {
    img: "fieldwise-import",
    doc: "fieldwise-import",
  },
  CLASS_MIGRATION: {
    doc: "class-section",
    img: "class-section",
  },
  STUDENT_MIGRATION: {
    doc: "student-migration",
    img: "student-migration",
  },
  PARENTS_MIGRATION: {
    doc: "parent-migration",
    img: "parent-migration",
  },
  FEE_INSTALLMENT_MIGRATION: {
    doc: "fee-installment-migration",
    img: "fee-installment-migration",
  },
  FEE_COMPONENT_MIGRATION: {
    doc: "fee-component-migration",
    img: "fee-component-migration",
  },
  FEE_COLLECTION_MIGRATION: {
    doc: "fee-collection-migration",
    img: "fee-collection-migration",
  },
  FEE_STRUCTURE_MIGRATION: {
    doc: "fee-structure-excel",
    img: "fee-structure-excel",
  },
  FEE_INSTALLMENT_IMPORT: {
    doc: "fee-installment-excel",
    img: "fee-installment-excel",
  },
  STUDENT_INFO_DOCS: {
    doc: "student-info-docs",
    img: "student-info-docs",
  },
};
